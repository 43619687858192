export default [
  {
    key: 'media_id',
    image: 'image_url',
    label: 'Ikon',
    type: 'image',
    required: true,
    notes: [
      'Wajib menggunakan gambar dengan <b>rasio kotak</b>.',
      'e.g. 480 x 480 px, 256 x 256 px, dll.',
    ],
  },
  {
    key: 'name',
    required: true,
    notes: 'It could be an event name, a promo code, etc.',
  },
  {
    key: 'url',
    label: 'Redirect URL',
    required: true,
  },
  {
    key: 'is_active',
    label: 'Status',
    type: 'radio',
    required: true,
    options: [
      { value: true, text: 'Aktif' },
      { value: false, text: 'Tidak aktif' },
    ],
  },
]
