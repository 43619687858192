<template>
  <b-card>
    <FormBuilder
      label="Banner"
      create
      :fields="fields"
      :loading-submit="$store.state.banner.loadingSubmit"
      @submit="submitData"
    />
  </b-card>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

import fields from '@/field/form/banner'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  setup() {
    return {
      fields,
    }
  },
  methods: {
    submitData(data, callback) {
      this.$store.dispatch('banner/create', data)
        .then(callback)
    },
  },
}
</script>

<style scoped>

</style>
